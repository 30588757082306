import React from "react";
import { TicketLine } from "./ticketLine";

export const TicketTable = ({stripeKey, title, products}) => {
    
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>{title}</th>
              <th />
              <th />
              <th />
            </tr>
            {products.map(product => (
              <TicketLine
                name={product.name}
                price={product.price}
                sku={product.sku}
                stripeKey={stripeKey}
                key={product.sku}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
};
