import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import MainPage from "./pages/main"
import SuccessPage from "./pages/success";

const App = () => (
  <React.Fragment>
    <Router>
      <React.Fragment>
        <Route path="/register/success.html" component={SuccessPage} />
        <Route exact path="*" component={MainPage} />
      </React.Fragment>
    </Router>
  </React.Fragment>
);

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
