const testConfig = {
  key: "pk_test_6CM8WDWwsvT1wAoqxSVwgNYz00mpm8djZ1",
  products: [
    {
      name: "Ordinary",
      price: "1200 NOK",
      sku: "sku_FKMUa3z0XcTIYU"
    }
  ]
};

export const prodConfig = {
  key: "pk_live_TGM1Nr6SLfWyvo5knnGHfOOt00QQLbbD35",
  products: [
    {
      name: "Ordinary",
      price: "1250 NOK",
      sku: "sku_FOTGSIjkTDSloU"
    }
  ]
};

export const discountApi = "https://fzb14ow021.execute-api.eu-north-1.amazonaws.com/Prod/discounts?code="

export const getConfig = test => {
  if (test) {
    return testConfig;
  }
  return prodConfig;
};
