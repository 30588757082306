import React, { Fragment, useState } from "react";

export const TicketLine = ({ name, price, sku, stripeKey }) => {
  const [count, setCount] = useState(1);

  const onCheckout = () => {
    const stripe = window.Stripe(stripeKey);
    stripe.redirectToCheckout({
      items: [{ sku: sku, quantity: count }],
      successUrl:
        window.location.protocol + "//conference.tarmflora.no/register/success.html",
      cancelUrl: window.location.protocol + "//conference.tarmflora.no"
    });
  };

  return (
    <Fragment>
      <tr>
        <td>{name}</td>
        <td>{price}</td>
        <td>
          <input
            type="number"
            name="quantity"
            value={count}
            onChange={e => setCount(parseInt(e.target.value))}
            min="1"
            max="9"
          />
          &nbsp; Qty
        </td>
        <td>
          <button role="link" onClick={onCheckout}>
            Checkout
          </button>
        </td>
      </tr>
    </Fragment>
  );
};
