import React from "react";
import { TicketTable } from "../TicketTable";
import { DiscountsArea } from "../DiscountsArea"
import { Footer } from "../footer"
import { getConfig } from "../config";

import "./../styles.css";

class MainPage extends React.Component {
    constructor(props) {
      super(props);
      this.title = "Event: Microbiota and future health";
      document.title = this.title;
    }

    componentDidMount() {
      const script = document.createElement("script");
  
      script.async = true;
      script.src = "https://js.stripe.com/v3";
      document.body.appendChild(script);
    }
  
    render() {
      const testMode = this.props.location.pathname === "/test";
      const config = getConfig(this.props.test);

      return (
        <div className="App">
            <div className="content-wrapper">
            <div>
            <h1><a href="https://conference.tarmflora.no">{this.title}</a></h1>
              <h2>September 26th @ 09 am - 16 pm</h2>
            </div>
            <div className="flex-container">
              <img src="banner.jpg" alt="conference" />
              <div className="info">
                <div> <p>Join the second year of the conference.</p>
                  The tickes includes:
                  <ul>
                    <li>Entrance</li>
                    <li>Lunch and coffee</li>
                  </ul>
                </div>

                <div className="contact">
                  <p>
                    Problems or questions? <br/> Don't hesitate to contact us at <br/>
                    </p>
                    <ul>
                      <li>(+47) 45889245</li>
                      <li>merete.eggesbo@fhi.no</li>
                    </ul>
                  
                </div>
              </div>
            </div>
            <TicketTable
              stripeKey = { config.key }
              title = "Tickets"
              products = { config.products }
            />
            {testMode && <h2>Testmode enabled</h2>}
            <DiscountsArea>
            </DiscountsArea>
          </div>

          <Footer/>
        </div>
      );
    }
  }
  export default MainPage;