import React from "react";

const SuccessPage = () => {

    return (
        <div className="success">
            <h1>You are registered for the event</h1>
            <h2>You will receive an email with confirmation and a receipt</h2>

            <a href="https://conference.tarmflora.no">Go to Conference website</a>
        </div>
    )
}

export default SuccessPage;