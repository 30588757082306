import React, { useState } from "react";
import axios from "axios"
import { discountApi, prodConfig } from "./config";
import { TicketTable } from "./TicketTable";

export const DiscountsArea = () => {
  const [code, setCode] = useState("");
  const [data, setData] = useState("");
  
  const submit = event => {
    event.preventDefault();
    axios.get(
      discountApi + code,
      {timeout: 10000 }
    )
    .then(response => {
      console.log(response.data.product);
      setData(response.data)}
      );
  }
  
  return (
    <div className="discount">
        Campaign code:
        <form onSubmit={submit}>
            <input value={code} onChange={e => setCode(e.target.value)} />
            <button>Check</button>
        </form>

        { data && data.product &&
          <TicketTable
            stripeKey = { prodConfig.key }
            title = "Campaigns"
            products = { [ data.product ] }
          />
        }
    </div>
  );
};
